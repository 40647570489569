// Navbar

.navbar {
  width: calc(100% - #{$sidebar-width-lg});
  height: $navbar-height;
  background: var(--#{$prefix}body-bg);
  border-bottom: 1px solid var(--#{$prefix}border-color);
  display: flex;
  align-items: center;
  padding: 0;
  position: fixed;
  right: 0;
  left: $sidebar-width-lg;
  z-index: $zindex-navbar;
  -webkit-box-shadow: 3px 0 10px 0 var(--#{$prefix}navbar-box-shadow-color);
          box-shadow: 3px 0 10px 0 var(--#{$prefix}navbar-box-shadow-color);
  transition: width .1s ease, left .1s ease;

  @media(max-width: 991px) {
    width: 100%;
    left: 0;
    .navbar-content {
      width: calc(100% - #{$sidebar-folded-width} - 1px);
    }
  }

  // Mini logo on small screen devices (mobile/tablet)
  .logo-mini-wrapper {
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
      margin-right: 24px;
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
    .logo-mini {
      width: 28px;
      height: 28px;
      &.logo-mini-light {
        [data-bs-theme='dark'] & {
          display: none;
        }
      }
      &.logo-mini-dark {
        [data-bs-theme='light'] & {
          display: none;
        }
      }
    }
  }

  .search-form {
    @extend .d-none;
    @extend .d-md-flex;
    @extend .align-items-center;
    width: 100%;
    margin-right: 60px;
    .input-group {
      .input-group-text {
        padding: 0;
        border: 0;
        color: $secondary;
        background: $input-bg;
        svg {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
      .form-control {
        border: 0;
        margin-top: 2px;
      }
    }
  }

  .navbar-content {
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 25px;
    padding-right: 25px;
    @include media-breakpoint-down(sm) {
      padding-left: 16px;
      padding-right: 16px;
    }
    .navbar-nav {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      .nav-item {
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        min-width: 30px;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(sm) {
          margin-left: 3px;
          margin-right: 3px;
        }
        .nav-link {
          color: var(--#{$prefix}navbar-item-color);
          padding: 0;
          position: relative;
          margin-left: auto;
          margin-right: auto;
          &:hover,
          &[aria-expanded="true"] {
            color: $primary;
          }
          &::after {
            display: none;
          }
          svg {
            width: 20px;
            height: 20px;
          }
          .indicator {
            position: absolute;
            top: 0px;
            right: 2px;
            .circle {
                background: $primary;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                &::before {
                    background-color: $primary;
                    content: "";
                    display: table;
                    border-radius: 50%;
                    position: absolute;
                    @extend .pulse-1;
                }
            }
          }
        }
        &.dropdown {
          @media(max-width: 767px) {
            position: static;
          }
          .dropdown-menu {
            width: max-content;
            position: absolute;
            right: -20px;
            left: auto;
            @extend .dropdownAnimation;
            &::before {
              content: '';
              width: 13px;
              height: 13px;
              background: $dropdown-bg;
              position: absolute;
              top: -7px;
              right: 28px;
              transform: rotate(45deg);
              border-top: 1px solid $dropdown-border-color;
              border-left: 1px solid $dropdown-border-color;
            }
            @media(max-width: 767px) {
              right: 20px;
              width: calc(100% - 40px);
              &::before{
                display: none;
              }
            }
          }
        }
        &.theme-switcher-wrapper {
          $scale: 48px;
          margin: 0 14px;
        
          input {
            &#theme-switcher {
              display: none;
            }
          }
        
          .box {
            width: $scale;
            height: calc($scale/2);
            background-color: $gray-300;
            border-radius: calc($scale/4);
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            .ball {
              width: calc($scale/2);
              height: calc($scale/2);
              background-color: $secondary;
              border-radius: 50%;
              border: calc($scale/16) solid $gray-300;
              position: absolute;
              transition: transform .1s ease;
            }
            .icons {
              display: flex;
              align-items: center;
              justify-content: space-around;
              flex-grow: 1;
              padding: 0 3px;
              i {
                color: $dark;
                font-size: calc($scale/2.8);
              }
            }
            &.light {
              .ball {
                transform: translate(0);
              }
            }
            &.dark {
              background-color: $gray-800;
              .ball {
                border-color: $gray-800;
                transform: translate(100%);
              }
              i {
                color: $warning;
              }
            }
          }
        }
      }
    }
  }

  // Sidebar-toggler on small devices (mobile/tablet)
  .sidebar-toggler {
    height: 100%;
    align-items: center;
    padding: 0 0 0 12px;
    display: none;
    svg {
      width: 26px;
      height: 26px;
      color: $secondary;
    }
    @media(max-width: 991px) {
      display: flex;    
    }
  }

}

@if $enable-dark-mode {
  @include color-mode(dark) {
    // Dark mode only code here..
  }
}