﻿.navbar .navbar-content {
    padding-left:8px;
    padding-right:8px;
}
.navbar-title {
    margin: auto 0;
    width: 100%;
    text-align: center;
}

.footer {
    padding: 5px 25px 5px;
}

.dropdown-item-wrap {
    white-space: normal;
}

.icon-smallertext {
    font-size: .8rem !important;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23031a61' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23031a61' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}