// Buttons

.btn {
  i {
    font-size: 1rem;
  }
  &.btn-rounded {
    @include border-radius(50px);
  }
  &.btn-xs {
    padding: $btn-padding-y-xs $btn-padding-x-xs;
    font-size: $btn-font-size-xs;
  }

  // Buttons with only icons 
  &.btn-icon {
    width: 38px;
    height: 38px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 18px;
    }
    &.btn-xs {
      width: 30px;
      height: 30px;
      svg {
        height: 14px;
      }
    }
    &.btn-sm {
      width: 36px;
      height: 36px;
      svg {
        height: 15px;
      }
    }
    &.btn-lg {
      width: 42px;
      height: 42px;
      svg {
        height: 20px;
      }
    }
  }

  // Buttons with icon and text 
  &.btn-icon-text {
    .btn-icon-prepend {
      margin-right: .5rem;
    }
    .btn-icon-append {
      margin-left: .5rem;
    }
    .btn-icon-prepend,
    .btn-icon-append {
      width: 18px;
      height: 18px;
    }
    &.btn-xs {
      .btn-icon-prepend,
      .btn-icon-append {
        width: 14px;
        height: 14px;
      }
    }
    &.btn-sm {
      .btn-icon-prepend,
      .btn-icon-append {
        width: 15px;
        height: 15px;
      }
    }
    &.btn-lg {
      .btn-icon-prepend,
      .btn-icon-append {
        width: 20px;
        height: 20px;
      }
    }
  }
}

// Social buttons
@each $color, $value in $social-colors {
  .btn-#{$color} {
    @include social-button(social-color($color));
  }
  .btn-outline-#{$color} {
    @include social-outline-button(social-color($color));
  }
}

// Inverse buttons 
@each $color, $value in $theme-colors {
  .btn-inverse-#{$color} {
    @include button-inverse-variant($value);
  }
}
