// npm package: select2
// github link: https://github.com/select2/select2

.select2-container--default {
  .select2-selection--single, 
  .select2-selection--multiple {
    background: $input-bg;
    border: 1px solid $input-border-color;
    border-radius: $border-radius;
    @at-root #{selector-append(".select2-container--focus", &)} {
      border: 1px solid $input-focus-border-color;
    }
  }
}

.select2-dropdown {
  background: $dropdown-bg;
  border: 1px solid $input-focus-border-color;
  border-radius: $dropdown-border-radius;
}

.select2-container--default {
  .select2-search--dropdown .select2-search__field {
    @extend .form-control;
  }
  .select2-results__option--highlighted[aria-selected] {
   background-color: $component-hover-bg;
   color: $component-hover-color; 
  }

  .select2-results__option[aria-selected=true] {
    background-color: $component-active-bg;
    color: $component-active-color
  }
  .select2-search--inline .select2-search__field {
    color: var(--#{$prefix}body-color);
  }
}

.select2-container .select2-selection--single,
.select2-container .select2-selection--multiple {
  height: auto;
}

.select2-container--default .select2-selection--single {
  .select2-selection__rendered {
    color: var(--#{$prefix}body-color);
    line-height: 1.5;
    padding: $input-btn-padding-y $input-btn-padding-x;
  }
  .select2-selection__arrow {
    height: 100%;
    b {
      left: 0;
    }
  }
}

.select2-container--default .select2-selection--multiple {
  min-height: 38px;
  .select2-selection__rendered {
    padding: 0 6px
  }
  .select2-selection__choice {
    background-color: $component-active-bg;
    color: $component-active-color;
    border-color: $component-active-bg;
    padding: 1px 8px;
    border-radius: .15rem;
    margin-top: 5px;
  }
  .select2-selection__choice__remove {
    color: $component-active-color;
    opacity: .5;
    margin-right: 4px;
  }
}

.select2-container .select2-search--inline {
  margin-top: 3px;
}