// Helper classes 

.grid-margin {
  margin-bottom: 1.5rem;
}
.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}
.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}
.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}
.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}



.stretch-card {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  >.card{
    width: 100%;
    min-width: 100%;
  }
}



.img-xxl {
  width: 200px;
  height: 200px;
}
.img-xl {
  width: 140px;
  height: 140px;
}
.img-lg {
  width: 100px;
  height: 100px;
}
.img-md {
  width: 75px;
  height: 75px;
}
.img-sm {
  width: 45px;
  height: 45px;
}
.img-xs {
  width: 35px;
  height: 35px;
}



.fw-boldest {
  font-weight: 900;
}



svg.icon-xs {
  width: 12px;
  height: 12px;
}
svg.icon-sm {
  width: 14px;
  height: 14px;
}
svg.icon-md {
  width: 16px;
  height: 16px;
}
svg.icon-lg {
  width: 20px;
  height: 20px;
}
svg.icon-xl {
  width: 26px;
  height: 26px;
}
svg.icon-xxl {
  width: 40px;
  height: 40px;
}



.icon-xs {
  font-size: 14px;
}
.icon-sm {
  font-size: 16px;
}
.icon-md {
  font-size: 18px;
}
.icon-lg {
  font-size: 20px;
}
.icon-xl {
  font-size: 24px;
}
.icon-xxl {
  font-size: 30px;
}



.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}



// Small paddings and margins
.pt-1px { padding-top: 1px }
.pt-2px { padding-top: 2px }
.pt-3px { padding-top: 3px }

.pb-1px { padding-bottom: 1px }
.pb-2px { padding-bottom: 2px }
.pb-3px { padding-bottom: 3px }

.mt-1px { margin-top: 1px }
.mt-2px { margin-top: 2px }
.mt-3px { margin-top: 3px }

.mb-1px { margin-bottom: 1px }
.mb-2px { margin-bottom: 2px }
.mb-3px { margin-bottom: 3px }