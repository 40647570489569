// Animation mixins 

@keyframes dropdownAnimation {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
    transform: translate3d(0, 0px , 0);
  }
}

.dropdownAnimation {
  -webkit-animation-name: dropdownAnimation;
          animation-name: dropdownAnimation;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOUt;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

.infinite-spin {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation-name: spin;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@mixin transition($settings) {
  -webkit-transition: $settings;
  -moz-transition: $settings;
  -ms-transition: $settings;
  -o-transition: $settings;
  transition: $settings;
}

.pulse-1 { // renamed 'pulse' to 'pulse-1' to avoid dropzone plugin pulse animation conflict
  $dim : 7px;
  $mult : 4;
  @keyframes pulse-1{
    0% {
      opacity: 1;
      width: $dim;
      height: $dim;
      left: 0;
      top: 0;
    }
    
    95% {
      opacity: 0.1;
      left: -(($dim * $mult) - $dim) * .5;
      top: -(($dim * $mult) - $dim) * .5;
      width: $dim * $mult;
      height: $dim * $mult;
    }
    
    100% {
      opacity: 0;
      width: $dim;
      height: $dim;
      left: 0;
      top: 0;
    }
  }
  animation-name: pulse-1;
  animation-duration: .9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

// Sidebar hamburger - START
@keyframes hamburger-animation-top {
  0% {
    top: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    top: 6px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    top: 6px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}

@keyframes hamburger-animation-top-2 {
  0% {
    top: 6px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  50% {
    top: 6px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    top: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes hamburger-animation-bottom {
  0% {
    bottom: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    bottom: 6px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    bottom: 6px;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
}

@keyframes hamburger-animation-bottom-2 {
  0% {
    bottom: 6px;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  50% {
    bottom: 6px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    bottom: 0;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

@keyframes hamburger-animation-scaled {
  50% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes hamburger-animation-scaled-2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
// Sidebar hamburger - END